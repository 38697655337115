<template>
	<div class="active-ticket">
		<div class="el-content">
			<a-space>
				<a-button @click="$router.go(-1)">返回</a-button>
				<a-button type="primary" @click="toEditTicket(0)">新增票种</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'票种名称',dataIndex:'title'},
				{title:'价格(元)',dataIndex:'price',slots:{customRender:'price'}},
				{title:'数量(张)',dataIndex:'number'},
				{title:'已报名人数',dataIndex:'regis_number'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #price="{record}">
					<span v-if="record.price > 0">{{record.price}}</span>
					<span v-else>免费</span>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button type="primary" title="编辑" icon="ri-edit-line" @click="toEditTicket(record)"></kd-button>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="delTicket(record.id)"></kd-button>
					</a-space>
				</template>
			</a-table>
			<a-modal v-model:visible="show.form" title="新增票种" @ok="saveForm" width="600px" @cancel="show.form = false">
				<a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
					<a-form-item label="票种名称">
						<a-input v-model:value="form.title"></a-input>
					</a-form-item>
					<a-form-item>
						<template #label>
							<span>票种价格</span>
							<a-tooltip title="价格设置为0 表示免费票">
								<i class="el-icon-info"></i>
							</a-tooltip>
						</template>
						<a-input v-model:value="form.price" addon-after="元"> </a-input>
					</a-form-item>
					<a-form-item label="票种数量">
						<a-input v-model:value="form.number" addon-after="张"></a-input>
					</a-form-item>
					<a-form-item label="报名此票种需要审核">
						<a-radio-group v-model:value="form.is_audit">
						    <a-radio :value="1">需要审核</a-radio>
						    <a-radio :value="0">不需要审核</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="可购票时间段">
						<a-range-picker v-model:value="time1" format="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item label="票券有效期">
						<a-range-picker v-model:value="time2" format="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item label="票种说明（选填）">
						<a-input v-model:value="form.summary" type="textarea"></a-input>
					</a-form-item>
				</a-form>
			</a-modal>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import activeModel from '@/api/addons/active'
import { useRoute } from 'vue-router'
import moment from 'moment'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
			},
			rid:0,
			form:null,
			show:{
				form:false,
			},
			time1:[],
			time2:[]
		})
		const opt = useRoute().query

		getTicketList(1,state.info.limit)

		function getTicketList(){
			activeModel.getActiveTicket(opt.id,res=>state.info.list = res)
		}

		function toEditTicket(row){
			state.form = {
				id:row ? row.id :0,
				rid:row ?row.rid:opt.id,
				title:row ?row.title:"",
				price:row ?row.price:0,
				number:row ?row.number:'',
				is_audit:row ?row.is_audit:1,
				end_regis:row ?row.end_regis:0,	//0活动结束前可订购 1时间范围
				active_aging:row ?row.active_aging:0,  //0活动期限有限 1时间段有效
				regis_start_time:row ?row.regis_start_time:'',	//订购开始时间
				regis_end_time:row ?row.regis_end_time:'',		//订购结束时间
				active_start_time:row ?row.active_start_time:'',	//票券有限期开始时间
				active_end_time:row ?row.active_end_time:'',		//票券有限期结束时间
				summary:row ?row.summary:'',
			}
			if( row ){
				state.time1 = [moment(row.regis_start_time),moment(row.regis_end_time)]
				state.time2 = [moment(row.active_start_time),moment(row.active_end_time)]
			}
			state.show.form = true
		}

		const saveForm = ()=>{
			let form = JSON.parse(JSON.stringify(state.form))
			if( state.time1.length > 0 ){
				[form.regis_start_time,form.regis_end_time ] = state.time1
			}
			if( state.time2.length > 0 ){
				[form.active_start_time,form.active_end_time ] = state.time2
			}
			activeModel.addOrEditTicket(form,()=> {
				getTicketList()
				state.show.form = false
			} )
		}

		const delTicket = (id)=>activeModel.deleteTicket(id,()=>getTicketList())

		return{
			...toRefs(state),
			getTicketList,
			saveForm,
			delTicket,
			toEditTicket
		}
	}
}
</script>

<style>
</style>
